// Libs
import $ from 'jquery';
window.$ = window.jQuery = $;

import debounce from 'lodash/debounce';
import { Foundation } from 'foundation-sites/js/foundation.core';
Foundation.addToJquery($);
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
Foundation.plugin(MediaQuery, 'MediaQuery');
import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu';
Foundation.plugin(DropdownMenu, 'DropdownMenu');
import { AccordionMenu } from 'foundation-sites/js/foundation.accordionMenu';
Foundation.plugin(AccordionMenu, 'AccordionMenu');
import { Reveal } from 'foundation-sites/js/foundation.reveal';
Foundation.plugin(Reveal, 'Reveal');
import AOS from 'aos';

// Components
import { utils } from './components/_utils';
import HeaderClass from './components/_header';
import ScrollToLink from './components/_scrollTo';
import { inputFile } from './components/_inputFile';

// Pages
import { filtre } from './pages/filtre.js';

// ----------------------------------- DOCUMENT READY -----------------------------------
// --------------------------------------------------------------------------------------
jQuery(function () {
    // --- INIT FUNCTIONS ---
    utils.checkDevice();
    jQuery(window).on('resize', debounce(function () {
        utils.checkDevice();
    }, 100));

    $(document).foundation();

    // Init scrollTo class
    let scrollTo = new ScrollToLink();
    scrollTo.init();

    // Init Header class
    let header = new HeaderClass();
    header.initHeader();

    // Manager de fichier uploadés dans un formulaire
    inputFile.init();

    $('.wysiwyg .button').each(function () {
        let thisParent = $(this).parent();

        if (thisParent.is('a')) {
            thisParent.addClass('no-decoration');
        }
    });

    // Execute AOS
    setTimeout(() => {
        AOS.init({
            once: true,
            anchorPlacement: 'top-center',
            offset: -250
        });

        $('[data-aos]').one("transitionend webkitTransitionEnd oTransitionEnd", function () {
            $(this).addClass('aos-end');
        });
    }, 500);



    let bodyClassList = document.getElementsByTagName('body')[0].classList;
    switch (true) {
        case bodyClassList.contains('page'):
            filtre.readyFn();
            break;
    }
});


$(window).on('load', function () {
    document.body.classList.add('loaded');

    let bodyClassList = document.getElementsByTagName('body')[0].classList;
    switch (true) {
    }
});