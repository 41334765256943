// IMPORT VENDOR
import Isotope from 'isotope-layout';
var jQueryBridget = require('jquery-bridget');


const filtre = {
    readyFn: function () {
        console.log("ready filter");
        jQueryBridget('isotope', Isotope, $);
        this.moreFiltre();
        this.filtre();

    },

    moreFiltre: function () {
        $(".more-filtre").on("click", function () {
            $(this).hide();
            $(this).closest(".container-filtre").addClass("all-filtre");
        });
    },

    filtre: function () {
        var $gridProduit = $('.grid-produit').isotope({
            itemSelector: '.cell-produit',
            layoutMode: 'fitRows'
        });

        $(".cell-filtre .tag").on("click", function () {
            $(this).toggleClass("actif");
            var filterValue = "";
            $(".cell-filtre .tag.actif").each(function () {
                filterValue += $(this).attr("data-filter");
            });
            if (filterValue == null || !filterValue) {
                $(".tag.all").addClass("actif");
            }
            $gridProduit.isotope({
                filter: filterValue
            });
            console.log("filterValues : " + filterValue);
        });
    }

}

export { filtre };